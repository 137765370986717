// $enable-shadows: true;
$is-rtl: false;
$rfs-breakpoint: 1300px;
$rfs-base-value: 30px;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1400px,
) !default;
$theme-colors: (
  "primary": #1a3028,
  "secondary": #ff4514,
  "danger": #ff4514,
  "warning": #ebe988,
  "success": #00cc47,
  "info": #a6c4e7,
  "dark": #000000,
);

$purple: #1a3028;
$primary: #1a3028;
$secondary: #ff4514;
$danger: #ff4514;
$warning: #ebe988;
$success: #00cc47;
$info: #a6c4e7;
$dark: #000000;
$body-bg: #fff;
$body-color: #3a3a3a;
$gray-400: #eeeeee;
$gray-500: #484848;
$gray-600: #575757;
$border-radius: 3rem;
$gray-300: #fcfafa;
$input-border-color: #20271b;
$input-bg: transparent;
$link-color: inherit;
$link-decoration: none;
$link-hover-decoration: none;
$link-hover-color: $primary;
$headings-font-weight: 700;
$btn-font-weight: 700;
$font-weight-bolder: 700;
$font-family-base: "Almarai", sans-serif;

$grid-gutter-width: 1.875rem;
$box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.06);
$table-radius: 0.3rem;
$input-padding-y: 0.6rem;

/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/transitions";

// Helpers
@import "../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

// Custom varbiables
$border-radius-xl: 3rem;
$box-shadow-lg: 0px 0px 15px 6px rgba(0, 0, 0, 0.1);
$box-shadow-xl: 0px 22px 33px rgba(0, 0, 0, 0.04);
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-family-heading: "Almarai", sans-serif;
@function aspectRatio($width, $height) {
  @return ($height / $width) * 100%;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($primary, 10%);
    outline: 0 solid $gray-400;
    border-radius: 20em;

    &:hover {
      background: $primary;
    }
  }
}

@function rem($size) {
  @return $size / 16px * 1rem;
}
