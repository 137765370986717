@import "./fonts";
@import "./bootstrap";
@import "./utils";
@import "./libs";
@import "../../node_modules/sal.js/dist/sal.css";
@import "./components/Btn.scss";

// Pages
@import "./pages/index.scss";
// libs
@import "swiper/swiper-bundle.css";
@import "./overide.scss";
html {
  scroll-behavior: smooth;
  ::-moz-selection {
    background: $secondary;
  }

  ::selection {
    background: $secondary;
  }
  font-size: 16px;
}
.theApp {
  &-body {
    overflow: hidden;
    height: 100vh;
  }
}
